export const ethnicityIdFieldName = 'ethnicityId';
export const formFields = {
  AGE: 'age',
  DAYS_IN_QUARANTINE: 'days_in_quarantine',
  DIRECT_CONTACT_WITH_COVID: 'direct_contact_with_covid',
  FLU_VACCINE: 'received_flu_vaccine',
  COVID_VACCINE: 'received_covid_vaccine',
  GENDER: 'gender',
  HEALTH_PROFESSIONAL_DETAILS: 'health_professional_details',
  OTHER: 'otherdetails',
  PHONE_NUMBER: 'phonenumber',
  RECEIVED_FLU_VACCINE: 'received_flu_vaccine',
  RESULT_FOR_COVID19_TEST: 'result_for_covid19_test',
  SEEN_HEALTH_PROFESSIONAL: 'seen_health_professional',
  START_FEELING_ILL_DATE: 'start_feeling_ill_date',
  SYMPTOMS: 'symptoms',
  TEMPERATURE: 'temperature',
  TEMPERATURE_MEASURED: 'measured',
  TESTED_FOR_COVID19: 'tested_for_covid19',
  TRAVEL_LOCATION: 'travel_location',
  TRAVELED_OUTSIDE_COUNTRY_LAST_14_DAYS:
    'traveled_outside_country_last_14_days',
  UNIT: 'unit',
  ZIP_CODE: 'zipcode',
  PEOPLE_AT_HOME: 'people_at_home',
  TIME_TO_SEE_HEALTH_PROFESSIONAL: 'time_to_see_professional',
  MEDICAL_TEST_DETAILS: 'medical_test_details',
  DIAGNOSIS_DETAILS: 'diagnosis_details',
  PRESCRIPTION_DETAILS: 'prescription_details',
  BIRTHDATE: 'birthdate',
  RACE_ID: 'raceId',
  ETHNICITY_ID: ethnicityIdFieldName,
  USER_SUBMITTED_RACE: 'userSubmittedRace',
  AT_HOME_TEST: 'at_home_test',
  AT_HOME_TEST_RESULTS: 'at_home_test_results',
};
