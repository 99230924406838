import React, { useState } from 'react';
import { FormHeader } from './FormHeader';
import { FormActions } from './FormActions';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { JoinForm, useJoinForm } from './useJoinForm';
import { NotificationSettings } from '~/components/AccountInformation/NotificationSettings';
import { AgreeTermsAndConditions } from '~/components/AgreeTermsAndConditions';
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';
import { matchFNYEmail } from '~/requests/user';
import Hero from '~/components/Hero';
import * as Styled from './styles';

export interface Props {
  loading: boolean;
  renderThankYouMessage: boolean;
  onValidSubmit: SubmitHandler<JoinForm>;
  onInvalidSubmit?: SubmitErrorHandler<JoinForm>;
  onCancel: () => void;
}

export const Form: React.FC<Props> = ({
  loading,
  onCancel,
  onValidSubmit,
  onInvalidSubmit,
  renderThankYouMessage,
}) => {
  const { t } = useTranslation();
  const { form, validationSchema } = useJoinForm();
  const [fnyEmailMatchMessage, setFNYEmailMatchMessage] = useState('');

  const getErrorMessage = (field: keyof JoinForm, errType?: string): string => {
    return errType ? t(`Join.form.${field}.errors.${errType}`) : '';
  };

  const verifyFNYEmail = async () => {
    if (form.errors.email) {
      return setFNYEmailMatchMessage('');
    }

    try {
      const email = form.getValues().email;
      const { data } = await matchFNYEmail({ email });
      if (data.hasMatch) {
        setFNYEmailMatchMessage(t('Join.form.email.fnyEmailMatchMessage'));
      } else {
        setFNYEmailMatchMessage('');
      }
    } catch {
      setFNYEmailMatchMessage('');
    }
  };

  return (
    <Hero>
      <Styled.FormContainer>
        <FormHeader renderThankYouMessage={renderThankYouMessage} />
        <TextField
          fullWidth
          name="nickname"
          variant="outlined"
          error={!!form.errors.nickname}
          label={t('Join.form.nickname.label')}
          inputProps={{
            'aria-label': t('Join.form.nickname.label'),
            'aria-describedby': 'nickname-helper-text',
            required: true,
          }}
          placeholder={t('Join.form.nickname.label')}
          inputRef={form.register(validationSchema.get('nickname'))}
          helperText={
            <span id="nickname-helper-text" aria-live="assertive">
              {getErrorMessage('nickname', form.errors?.nickname?.type)}
            </span>
          }
          data-test="nickname_input"
        />
        <TextField
          fullWidth
          type="email"
          name="email"
          variant="outlined"
          data-test="email_input"
          onBlur={verifyFNYEmail}
          error={!!form.errors.email}
          label={t('Join.form.email.label')}
          inputProps={{
            'aria-label': t('Join.form.email.label'),
            'aria-describedby': 'email-helper-text',
            required: true,
          }}
          placeholder={t('Join.form.email.label')}
          inputRef={form.register(validationSchema.get('email'))}
          helperText={
            <span id="email-helper-text" aria-live="assertive">
              {getErrorMessage('email', form.errors?.email?.type) ||
                fnyEmailMatchMessage}
            </span>
          }
        />
        <Styled.PwdInput
          name="password"
          variant="outlined"
          data-test="password_input"
          error={!!form.errors.password}
          inputProps={{
            'aria-label': t('Join.form.password.label'),
            'aria-describedby': 'password-helper-text',
            required: true,
          }}
          label={t('Join.form.password.label')}
          inputRef={form.register(validationSchema.get('password'))}
          helperText={
            <span id="password-helper-text" aria-live="assertive">
              {getErrorMessage('password', form.errors?.password?.type)}
            </span>
          }
        />
        <Styled.PwdInput
          name="confirmPassword"
          data-test="confirmPassword_input"
          error={!!form.errors.confirmPassword}
          label={t('Join.form.confirmPassword.label')}
          inputProps={{
            'aria-label': t('Join.form.confirmPassword.label'),
            'aria-describedby': 'confirmPassword-helper-text',
            required: true,
          }}
          inputRef={form.register(validationSchema.get('confirmPassword'))}
          helperText={
            <span id="confirmPassword-helper-text" aria-live="assertive">
              {getErrorMessage(
                'confirmPassword',
                form.errors?.confirmPassword?.type
              )}
            </span>
          }
        />
        <Styled.RemindersQuestion>
          {t('Join.form.notifications.question')}
        </Styled.RemindersQuestion>
        <NotificationSettings
          form={form}
          variant="outlined"
          translationPath="Join.form"
          getErrorMessage={getErrorMessage}
          validationSchema={validationSchema}
        />
      </Styled.FormContainer>
      <FormActions
        loading={loading}
        onCancelButtonClick={onCancel}
        onJoinButtonClick={form.handleSubmit(onValidSubmit, onInvalidSubmit)}
      />
      <AgreeTermsAndConditions fullWidth />
    </Hero>
  );
};
